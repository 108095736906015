import { message } from 'antd';
import 'antd/dist/antd.min.css';
import React, { useCallback, useEffect, useMemo, useRef, useReducer } from 'react';
import styled, { css } from 'styled-components';
import { Image } from '../models/media.model';
import { useUploadImageMutation } from '../services/media.service';
import EditorPreview from './EditorPreview';
import EditorToolbar from './EditorToolbar';
import EditorView from './EditorView';

import { EditorContext as EC, editorReducer } from './EditorContext';

interface Props {
  value?: string;
  height?: number;
  onChange?: (md: string, html: string) => void;
  onSave?: () => void;
  acceptTypes?: string;
}

const Editor = styled.div<{
  isFullScreen: boolean;
  editorHeight: number;
}>`
  & {
    display: flex;
    flex-direction: column;
  }

  ${({ isFullScreen, editorHeight }) =>
    isFullScreen
      ? // các thư viện h để zindex là 100
        css`
          position: fixed !important;
          top: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 100;
        `
      : css`
          /* height: ${editorHeight}px; */
          max-width: auto;
        `}
`;

const Wrapper = styled.div`
  & {
    display: flex;
    flex: 1;
  }
`;

const CMEditor: React.FC<Props> = ({
  value = '',
  height = 500,
  onChange,
  acceptTypes = 'image/jpg,image/jpeg,image/png',
}) => {
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [uploadImg] = useUploadImageMutation();

  const uploadFunction = useCallback(
    (file: File, title: string, onSuccess: (url: string) => void): void => {
      const form = new FormData();
      form.append('upload', file);
      form.append('title', title);

      uploadImg({ data: form })
        .unwrap()
        .then((image: Image) => {
          onSuccess(image.url);
        })
        .catch((error: { data: string }) => {
          message.error(error.data);
        });
    },
    [],
  );

  const fileTypes = useMemo(() => acceptTypes.split(','), []);
  const validateFile = useCallback((file: File) => {
    return fileTypes.includes(file.type);
  }, []);

  const [state, dispatch] = useReducer(editorReducer, {
    md: value,
    html: '',
    preview: false,
    splitScreen: false,
    fullscreen: false,
    currentActive: 'editor',
    scrollPosition: 0,
    acceptTypes,
    editorHeight: height,

    uploadFunction,
    validateFile,
  });

  // const [debounced] = useDebounce(state.md, 1000, { maxWait: 2000, leading: true, trailing: true });

  useEffect(() => {
    if (!toolbarRef.current) return;

    const toolbarHeight = toolbarRef.current.clientHeight;
    const screenHeight = document.documentElement.clientHeight;

    dispatch({
      type: 'editorHeightChanged',
      payload: state.fullscreen ? screenHeight - toolbarHeight : height - toolbarHeight,
    });
  }, [toolbarRef, state.fullscreen]);

  useEffect(() => {
    onChange && onChange(state.md, state.html);
  }, [state.md]);

  return (
    <EC.Provider value={{ state, dispatch }}>
      <Editor isFullScreen={!!state.fullscreen} editorHeight={state.editorHeight}>
        <EditorToolbar ref={toolbarRef} />

        <Wrapper>
          <EditorView />
          {state.preview && <EditorPreview />}
        </Wrapper>
      </Editor>
    </EC.Provider>
  );
};

export default CMEditor;
