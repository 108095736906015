import { ViewUpdate } from '@codemirror/view';

export const newLineForSpecSyntax = (update: ViewUpdate, specArr: string[]) => {
  if (!update.docChanged) return;

  const { state } = update.view;
  const { doc, selection } = state;
  const cursorPosition = selection.main.head;
  const beforeCursor = doc.sliceString(
    Math.max(0, cursorPosition - 2),
    cursorPosition,
  );

  if (specArr.includes(beforeCursor)) {
    const line = doc.lineAt(cursorPosition - 2);
    const previousLineContent = doc.line(line.number - 1).text.trim();

    if (previousLineContent) {
      const transaction = state.update({
        changes: {
          from: cursorPosition - 2,
          to: cursorPosition - 2,
          insert: '\n',
        },
      });
      update.view.dispatch(transaction);
    }
  }
};
