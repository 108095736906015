import { Progress as AntProgress, type ProgressProps } from 'antd';
import styled from 'styled-components';
import type { WebTarget } from 'styled-components';

type StyledProgressProps = ProgressProps & WebTarget;

const Progress = styled<StyledProgressProps>(AntProgress)`
  .ant-progress-inner {
    border-radius: unset;
    background-color: rgba(2, 117, 216, 0.43);
    .ant-progress-success-bg,
    .ant-progress-bg {
      height: 18px !important;
      border-radius: unset;
      background-color: #ee6464;
    }
    .ant-progress-success-bg {
      background-color: #0275d8;
    }
  }
`;

export default Progress;
