import { Form as AntForm } from 'antd';
import styled from 'styled-components';

const Form = styled(AntForm)`
  font-size: 16px;
  .ant-form-item-label > label {
    font-size: 16px;
    font-weight: 400;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    content: none;
  }
`;
export default Form;
