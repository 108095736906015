import { EditorView as CMEditorView } from '@codemirror/basic-setup';
import { Text as CMText } from '@codemirror/state';
import { EditorSelection as CMEditorSelection } from '@codemirror/state';
import { countAllArrString } from 'libraries/utils/string';

const viewKit = (view: CMEditorView, lineNumber?: number) => {
  const getSelections = () =>
    view.state.selection.ranges.map((r) => view.state.sliceDoc(r.from, r.to));
  const setCursor = (pos: number) =>
    view.dispatch({ selection: { anchor: pos } });
  const lineAt = (number: number | undefined = lineNumber) =>
    number && view.state.doc.lineAt(number);
  const textAtLine = (number: number | undefined = lineNumber) =>
    number && view.state.doc.lineAt(number).text;

  const cursor = view.state.selection.main.head;
  const currentLine = view.state.doc.lineAt(
    view.state.selection.main.head,
  ).number;
  const line = view.state.doc.lineAt(view.state.selection.main.head);
  const lineText = view.state.doc.lineAt(view.state.selection.main.head).text;

  const somethingSelected = view.state.selection.ranges.some((r) => !r.empty);
  const listSelection = view.state.selection.ranges;
  const trimmedSelection = getSelections()[0].trim();
  const isTrailing = !!(getSelections()[0].length - trimmedSelection.length);
  return {
    line,
    currentLine,
    lineText,
    somethingSelected,
    getSelections,
    setCursor,
    listSelection,
    cursor,
    lineAt,
    textAtLine,
    trimmedSelection,
    isTrailing,
  };
};

const header = (view: CMEditorView, type: number) => {
  const { lineText, line, setCursor } = viewKit(view);
  if (lineText) {
    setCursor(line.from);
  }
  view.dispatch(
    view.state.changeByRange((range) => {
      const headerMark = () => {
        const mark: any[] = [];
        for (let i = 1; i <= type; i++) {
          mark.push('#');
        }
        return mark.join('');
      };
      return {
        changes: [
          {
            from: range.from,
            insert: CMText.of([`${headerMark()} `]),
          },
        ],
        range: CMEditorSelection.range(
          range.from + type + 1,
          range.to + type + 1,
        ),
      };
    }),
  );
  if (lineText) {
    setCursor(line.to + type + 1);
  }
};

const bold = (view: CMEditorView) => {
  const { trimmedSelection, isTrailing } = viewKit(view);
  view.dispatch(
    view.state.replaceSelection(trimmedSelection),
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert: CMText.of(['**']),
          },
          {
            from: range.to,
            insert: isTrailing ? CMText.of(['**', '']) : CMText.of(['**']),
          },
        ],
        range: CMEditorSelection.range(range.from + 2, range.to + 2),
      };
    }),
  );
};

const italic = (view: CMEditorView) => {
  const { trimmedSelection, isTrailing } = viewKit(view);
  view.dispatch(
    view.state.replaceSelection(trimmedSelection),
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert: CMText.of(['*']),
          },
          {
            from: range.to,
            insert: isTrailing ? CMText.of(['*', '']) : CMText.of(['*']),
          },
        ],
        range: CMEditorSelection.range(range.from + 1, range.to + 1),
      };
    }),
  );
};

const strikethrough = (view: CMEditorView) => {
  const { trimmedSelection, isTrailing } = viewKit(view);
  view.dispatch(
    view.state.replaceSelection(trimmedSelection),
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert: CMText.of(['~~']),
          },
          {
            from: range.to,
            insert: isTrailing ? CMText.of(['~~', '']) : CMText.of(['~~']),
          },
        ],
        range: CMEditorSelection.range(range.from + 2, range.to + 2),
      };
    }),
  );
};
const code = (view: CMEditorView) => {
  const { trimmedSelection, isTrailing } = viewKit(view);

  view.dispatch(
    view.state.replaceSelection(trimmedSelection),
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert: CMText.of(['`']),
          },
          {
            from: range.to,
            insert: isTrailing ? CMText.of(['`', '']) : CMText.of(['`']),
          },
        ],
        range: CMEditorSelection.range(range.from + 1, range.to + 1),
      };
    }),
  );
};

const blockCode = (view: CMEditorView) => {
  const { lineText, somethingSelected, getSelections, setCursor, line } =
    viewKit(view);
  view.dispatch(
    view.state.changeByRange((range) => {
      return lineText.length
        ? somethingSelected
          ? {
              changes: [
                {
                  from: range.from,
                  to: range.to,
                  insert: CMText.of([
                    '```',
                    ...getSelections()[0].split('\n'),
                    '```',
                  ]),
                },
              ],
              range: CMEditorSelection.range(range.from + 4, range.to + 4),
            }
          : {
              changes: [
                {
                  from: range.from,
                  insert: CMText.of(['', '```', '']),
                },
                {
                  from: range.to,
                  insert: CMText.of(['', '```']),
                },
              ],
              range: CMEditorSelection.range(range.from + 4, range.to + 4),
            }
        : {
            changes: [
              {
                from: range.from,
                insert: CMText.of(['```', '', '```']),
              },
            ],
            range: CMEditorSelection.range(range.from + 3, range.to + 3),
          };
    }),
  );
  if (lineText.length && somethingSelected) setCursor(line.from + 3);
};
const tabs = (view: CMEditorView) => {
  const {
    lineText,
    somethingSelected,
    getSelections,
    setCursor,
    line,
    listSelection,
  } = viewKit(view);

  view.dispatch(
    view.state.changeByRange((range) => {
      return lineText.length
        ? somethingSelected
          ? {
              changes: [
                {
                  from: range.from,
                  to: range.to,
                  insert: CMText.of([
                    '```[t1:tab1]',
                    ...getSelections()[0].split('\n'),
                    '```',
                  ]),
                },
              ],
              range: CMEditorSelection.range(range.from + 4, range.to + 4),
            }
          : {
              changes: [
                {
                  from: range.from,
                  insert: CMText.of(['', '```[t1:Name tab1]', 'Content tab1']),
                },
                {
                  from: range.to,
                  insert: CMText.of(['', '```', '']),
                },
                {
                  from: range.from,
                  insert: CMText.of(['', '```[t1:Name tab2]', 'Content tab2']),
                },
                {
                  from: range.to,
                  insert: CMText.of(['', '```']),
                },
              ],
              range: CMEditorSelection.range(range.from + 4, range.to + 4),
            }
        : {
            changes: [
              {
                from: range.from,
                insert: CMText.of([
                  '```[t1:Name tab1]',
                  'Content tab1',
                  '```',
                  '',
                ]),
              },
              {
                from: range.from,
                insert: CMText.of(['```[t1:Name tab2]', 'Content tab2', '```']),
              },
            ],
            range: CMEditorSelection.range(range.from + 3, range.to + 3),
          };
    }),
  );
  if (lineText.length && somethingSelected) setCursor(line.from + 3);
};

const mermaid = (view: CMEditorView) => {
  const { lineText, somethingSelected, getSelections, setCursor, line } =
    viewKit(view);
  view.dispatch(
    view.state.changeByRange((range) => {
      return lineText.length
        ? somethingSelected
          ? {
              changes: [
                {
                  from: range.from,
                  to: range.to,
                  insert: CMText.of([
                    '~~~mermaid',
                    ...getSelections()[0].split('\n'),
                    '~~~',
                  ]),
                },
              ],
              range: CMEditorSelection.range(range.from + 4, range.to + 4),
            }
          : {
              changes: [
                {
                  from: range.from,
                  insert: CMText.of([
                    '',
                    '~~~mermaid',
                    'graph TD',
                    'A[Christmas] -->|Get money| B(Go shopping)',
                    'B --> C{Let me think}',
                    'C -->|One| D[Laptop]',
                    'C -->|Two| E[iPhone]',
                    'C -->|Three| F[Car]',
                  ]),
                },
                {
                  from: range.to,
                  insert: CMText.of(['', '~~~']),
                },
              ],
              range: CMEditorSelection.range(range.from + 4, range.to + 4),
            }
        : {
            changes: [
              {
                from: range.from,
                insert: CMText.of([
                  '~~~mermaid',
                  'graph TD',
                  'A[Christmas] -->|Get money| B(Go shopping)',
                  'B --> C{Let me think}',
                  'C -->|One| D[Laptop]',
                  'C -->|Two| E[iPhone]',
                  'C -->|Three| F[Car]',
                  '~~~',
                ]),
              },
            ],
            range: CMEditorSelection.range(range.from + 3, range.to + 3),
          };
    }),
  );
  if (lineText.length && somethingSelected) setCursor(line.from + 3);
};

const link = (view: CMEditorView, text?: string, url?: string) => {
  view.dispatch(
    view.state.changeByRange((range) => {
      const selectionText = view.state.sliceDoc(range.from, range.to);
      const anchor = text || selectionText;
      const link = `[${anchor}](${url ?? ''})`;
      const backspace = url ? 0 : 1;

      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of([link]),
          },
        ],
        range: CMEditorSelection.range(
          range.from + link.length - backspace,
          range.to + link.length - anchor.length - backspace,
        ),
      };
    }),
  );
};

const bulletList = (view: CMEditorView) => {
  const {
    lineText,
    somethingSelected,
    getSelections,
    setCursor,
    line,
    listSelection,
  } = viewKit(view);
  const textList = getSelections()[0]
    .split('\n')
    .map((string: string) => `* ${string}`);

  if (lineText.length) {
    setCursor(line.from);
  }
  view.dispatch(
    view.state.changeByRange((range) => {
      return !somethingSelected
        ? {
            changes: [
              {
                from: range.from,
                to: range.to,
                insert: CMText.of(['* ']),
              },
            ],
            range: CMEditorSelection.range(range.from + 2, range.to + 2),
          }
        : {
            changes: [
              {
                from: listSelection[0].from,
                to: listSelection[0].to,
              },
              {
                from: listSelection[0].from,
                insert: CMText.of([...textList]),
              },
            ],
            range: CMEditorSelection.range(range.from, range.to),
          };
    }),
  );
  somethingSelected
    ? setCursor(listSelection[0].to + textList.length * 2)
    : setCursor(line.to + 2);
};

const image = (view: CMEditorView, alt?: string, url?: string) => {
  const img = `![${alt ?? 'text'}](${url ?? 'https://'})`;

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert: CMText.of([img]),
          },
        ],
        range: CMEditorSelection.range(
          range.from + (url ? img.length : img.length - 1),
          range.to + (url ? img.length : img.length - 1),
        ),
      };
    }),
  );
};

const numberList = (view: CMEditorView) => {
  const {
    lineText,
    somethingSelected,
    getSelections,
    setCursor,
    line,
    listSelection,
  } = viewKit(view);

  const textList = getSelections()[0]
    .split('\n')
    .map((string: string, index) => `${index + 1}. ${string}`);
  if (lineText.length) {
    // Nếu con trỏ chuột ở trên dòng có chữ , lúc bấm nút sẽ đặt lại vị trí con trỏ ở đầu dong
    setCursor(line.from);
  }
  view.dispatch(
    view.state.changeByRange((range) => {
      return !somethingSelected
        ? {
            changes: [
              {
                from: range.from,
                insert: CMText.of(['1. ']),
              },
            ],
            range: CMEditorSelection.range(range.from, range.to),
          }
        : {
            changes: [
              {
                from: listSelection[0].from,
                to: listSelection[0].to,
              },
              {
                from: listSelection[0].from,
                insert: CMText.of([...textList]),
              },
            ],
            range: CMEditorSelection.range(range.from, range.to),
          };
    }),
  );
  // sau khi đã thực hiện thay đổi đặt lại con trỏ chuột ở cuối dong
  somethingSelected
    ? setCursor(listSelection[0].to + textList.length * 3)
    : setCursor(line.to + 3);
};

const align = (
  view: CMEditorView,
  direction: 'center' | 'right' | 'left' | 'justify',
) => {
  const openingTag = `::: ${direction}`;
  const closingTag = ':::';

  const { lineText, somethingSelected } = viewKit(view);
  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert:
              lineText && !somethingSelected
                ? // nếu trên dòng có ký tự thì xuống dòng, không tính trường hợp bôi đen
                  CMText.of(['', openingTag, ''])
                : CMText.of([openingTag, '']),
          },
          {
            from: range.to,
            insert: CMText.of(['', closingTag]),
          },
        ],
        range: CMEditorSelection.range(
          range.from +
            openingTag.length +
            (lineText && !somethingSelected ? 2 : 1),
          // nếu con trỏ chuột ở trên 1 dòng có chữ thì xuống dòng, nếu bôi đen r bấm nút thì con trỏ chuột nằm ở cuối văn bàn
          range.to +
            openingTag.length +
            (lineText && !somethingSelected ? 2 : 1),
        ),
      };
    }),
  );
};

const table = (view: CMEditorView, row = 3, column = 3) => {
  const columnGenerator = (
    type: 'header' | 'separator' | 'content' = 'content',
  ) => {
    return Array.from({ length: column })
      .fill(null)
      .map((_, i) => {
        switch (type) {
          case 'header':
            if (i + 1 === column) return `| Column ${i + 1} |`;
            return `| Column ${i + 1} `;
          case 'separator':
            if (i + 1 === column) return `| -------- |`;
            return '| -------- ';
          case 'content':
            if (i + 1 === column) return `| Text |`;
            return '| Text ';
        }
      });
  };
  const header = columnGenerator('header').join('');
  const separator = columnGenerator('separator').join('');
  const content = columnGenerator('content').join('');
  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of([
              '',
              header,
              separator,
              ...Array.from<string>({ length: row }).fill(content),
              '',
            ]),
          },
        ],
        range: CMEditorSelection.range(range.from, range.to),
      };
    }),
  );
};

const horizontalLine = (view: CMEditorView) => {
  const { lineText, cursor, line } = viewKit(view);
  const getSelectionBeforeCursor = view.state.sliceDoc(line.from, cursor);
  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert:
              !lineText || !getSelectionBeforeCursor
                ? CMText.of(['', '---', '', ''])
                : CMText.of(['', '', '---', '', '']),
          },
        ],
        range: CMEditorSelection.range(
          range.from + (!lineText || !getSelectionBeforeCursor ? 6 : 7),
          range.to + (!lineText || !getSelectionBeforeCursor ? 6 : 7),
        ),
      };
    }),
  );
};

const pasteYoutube = (view: CMEditorView, url: string) => {
  view.dispatch(
    view.state.changeByRange((range) => {
      const makeUrlObj = (string: string) => {
        let urlObj;
        try {
          urlObj = new URL(string);
        } catch {
          return undefined;
        }
        return urlObj;
      };
      const urlObj = makeUrlObj(url);
      const urlId =
        urlObj?.searchParams.get('v') ?? urlObj?.pathname.replace('/', '');
      const link = `<iframe style="max-width: 560px; width: 100%; aspect-ratio: 16 / 9;" src="https://www.youtube.com/embed/${urlId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
      return {
        changes: [
          {
            from: range.from,
            insert: CMText.of([link, '', '']),
          },
        ],
        range: CMEditorSelection.range(
          range.from + link.length + 2,
          range.to + link.length + 2,
        ),
      };
    }),
  );
};

const highlight = (view: CMEditorView) => {
  const { getSelections } = viewKit(view);
  const trimmedSelection = getSelections()[0].trim();
  const isTrailing = !!(getSelections()[0].length - trimmedSelection.length);

  view.dispatch(
    view.state.replaceSelection(trimmedSelection),
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert: CMText.of(['==']),
          },
          {
            from: range.to,
            insert: isTrailing ? CMText.of(['==', '']) : CMText.of(['==']),
            // cho thêm một dòng nữa nếu trailing để cân bằng lại whitespace bị thiếu
          },
        ],
        range: CMEditorSelection.range(range.from + 2, range.to + 2),
      };
    }),
  );
};

const labels = (view: CMEditorView) => {
  const { lineText, setCursor, line } = viewKit(view);

  const defaultExam = [
    '#[Information](yellow)',
    '#[mobile](blue)',
    '#[web](red)',
  ];

  const numberStr = countAllArrString(defaultExam);

  if (lineText.length) {
    setCursor(line.from);
  }

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of(defaultExam),
          },
        ],
        range: CMEditorSelection.range(range.from + 2, range.to + 2),
      };
    }),
  );

  setCursor(numberStr + defaultExam.length - 1);
};

const collapsible = (view: CMEditorView) => {
  const { lineText, setCursor, line } = viewKit(view);

  const defaultExam = [
    '+++> Click me!',
    'Hidden text',
    '+++ Nested',
    'Inner hidden text',
    '+++',
    '+++>',
  ];

  const countChar = countAllArrString(defaultExam);

  if (lineText.length) {
    setCursor(line.from);
  }

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of(defaultExam),
          },
        ],
        range: CMEditorSelection.range(range.from + 2, range.to + 2),
      };
    }),
  );

  setCursor(countChar + defaultExam.length - 1);
};

const uml = (view: CMEditorView) => {
  const { lineText, setCursor, line } = viewKit(view);

  const defaultExam = [
    '@startuml',
    'User --> (registers an account)',
    '@enduml',
  ];

  const countChar = countAllArrString(defaultExam);

  if (lineText.length) {
    setCursor(line.from);
  }

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of(defaultExam),
          },
        ],
        range: CMEditorSelection.range(range.from + 2, range.to + 2),
      };
    }),
  );

  setCursor(countChar + defaultExam.length - 1);
};

const treelist = (view: CMEditorView) => {
  const { lineText, setCursor, line } = viewKit(view);

  const defaultExam = ['root', '+-- item1', '+-- sub-item1', '+-- item2'];

  const countChar = countAllArrString(defaultExam);

  if (lineText.length) {
    setCursor(line.from);
  }

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of(defaultExam),
          },
        ],
        range: CMEditorSelection.range(range.from + 2, range.to + 2),
      };
    }),
  );

  setCursor(countChar + defaultExam.length - 1);
};

const alert = (view: CMEditorView, type: alerts) => {
  const openingTag = `> [!${type}]`;
  const closingTag = '> Your alert';

  const { lineText, somethingSelected } = viewKit(view);

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert: lineText
              ? CMText.of(['', openingTag, closingTag])
              : CMText.of([openingTag, closingTag]), // Thêm openingTag
          },
        ],
        range: CMEditorSelection.range(
          range.from + openingTag.length,
          range.to + closingTag.length, // Điều chỉnh vị trí con trỏ sau khi thêm các thẻ
        ),
      };
    }),
  );
};
const underline = (view: CMEditorView) => {
  const { trimmedSelection, isTrailing, setCursor } = viewKit(view);
  view.dispatch(
    view.state.replaceSelection(trimmedSelection),
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert: CMText.of(['++']),
          },
          {
            from: range.to,
            insert: isTrailing ? CMText.of(['++', '']) : CMText.of(['++']),
          },
        ],
        range: CMEditorSelection.range(range.from + 2, range.to + 4),
      };
    }),
  );

  setCursor(2);
};

export type alerts = 'note' | 'tip' | 'important' | 'caution' | 'warning';

export interface Markers {
  bold: (view: CMEditorView) => void;
  underline: (view: CMEditorView) => void;
  uml: (view: CMEditorView) => void;
  alert: (view: CMEditorView, type: alerts) => void;
  italic: (view: CMEditorView) => void;
  collapsible: (view: CMEditorView) => void;
  strikethrough: (view: CMEditorView) => void;
  link: (view: CMEditorView, text?: string, url?: string) => void;
  image: (view: CMEditorView, alt?: string, url?: string) => void;
  code: (view: CMEditorView) => void;
  blockCode: (view: CMEditorView) => void;
  tabs: (view: CMEditorView) => void;
  mermaid: (view: CMEditorView) => void;
  labels: (view: CMEditorView) => void;
  bulletList: (view: CMEditorView) => void;
  numberList: (view: CMEditorView) => void;
  treelist: (view: CMEditorView) => void;
  align: (
    view: CMEditorView,
    direction: 'left' | 'center' | 'right' | 'justify',
  ) => void;
  table: (view: CMEditorView, row: number, column: number) => void;
  horizontalLine: (view: CMEditorView) => void;
  pasteYoutube: (view: CMEditorView, url?: string) => void;
  header: (view: CMEditorView, type: number) => void;
  highlight: (view: CMEditorView) => void;
}

export default {
  bold,
  italic,
  strikethrough,
  code,
  link,
  image,
  blockCode,
  bulletList,
  numberList,
  align,
  table,
  horizontalLine,
  pasteYoutube,
  header,
  highlight,
  tabs,
  mermaid,
  labels,
  underline,
  collapsible,
  treelist,
  uml,
  alert,
} as Markers;
