import { AlertOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { ReactElement, useMemo } from 'react';
import { alerts } from '../markers';
import '../toolBarBtns/css/menu.css';

interface iHeaderBtn {
  handleAlert?: (type: alerts) => void;
}
const DropdownAlert = ({ handleAlert }: iHeaderBtn): ReactElement => {
  const onClick = ({ key }: any) => {
    handleAlert && handleAlert(key);
  };
  const menu = useMemo(() => {
    return (
      <Menu onClick={onClick}>
        <Menu.Item className="menu-item note-alert" key={'note'}>
          Note
        </Menu.Item>
        <Menu.Item className="menu-item tip-alert" key={'tip'}>
          Tip
        </Menu.Item>
        <Menu.Item className="menu-item important-alert" key={'important'}>
          Important
        </Menu.Item>
        <Menu.Item className="menu-item caution-alert" key={'caution'}>
          Caution
        </Menu.Item>
        <Menu.Item className="menu-item warning-alert" key={'warning'}>
          Warning
        </Menu.Item>
      </Menu>
    );
  }, [onClick]);
  return (
    <Dropdown overlay={menu}>
      <AlertOutlined />
    </Dropdown>
  );
};

const AlertBtn = ({ handleAlert }: iHeaderBtn) => {
  return <Button title="Alert" type="text" icon={<DropdownAlert handleAlert={handleAlert} />} />;
};

export default AlertBtn;
