import { ReactElement, useEffect, useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import ShowMarkdown from '../ShowMarkdown';
import Modal from '../../commons/Modal';
import styled from 'styled-components';

function convertContentHtml(text: string) {
  return { __html: text };
}

const Wrapper = styled(TableContainer)``;

const guideComponents = [
  {
    content: `<p>Hạn chế sử dụng h1 vì nó dùng để đặt tiêu đề</p><h2>## h2 Heading</h2><h3>### h3 Heading</h3><h4>#### h4 Heading</h4>`,
    markdown: `Hạn chế sử dụng h1 vì nó dùng để đặt tiêu đề\n\n## h2 Heading\n### h3 Heading\n#### h4 Heading`,
  },
  {
    content: `<h2>## Nhấn mạnh chữ</h2>
    <p>**In đậm**</p>
    <p>*In nghiêng*</p>
    <p>++Gạch chân++</p>
    <p>~~Gạch ngang~~</p>
    <p>==Highlight==</p>
    `,
    markdown: `## Nhấn mạnh chữ\n\n**In đậm**\n\n*In nghiêng*\n\n++Gạch chân++\n\n~~Gạch ngang~~\n\n==Highlight==`,
  },
  {
    content: `<h2>## Nhãn</h2>
    <p>#[Information](yellow)</p>
    <p>#[mobile](blue)</p>
    <p>#[web](red)</p>
    `,
    markdown: `## Nhãn\n\n> #[Information](yellow)\n> #[mobile](blue)\n#[web](red)`,
  },
  {
    content: `<h2>## Căn lề</h2>
    <p>::: left</p>
    <p>Contents to align left</p>
    <p>:::</p>

    <p>::: center</p>
    <p>Contents to align center</p>
    <p>:::</p>

    <p>::: right</p>
    <p>Contents to align right</p>
    <p>:::</p>

    <p>::: justify</p>
    <p>Contents to align justify</p>
    <p>:::</p>
    `,
    markdown: `## Căn lề\n\n::: left\nContents to align left\n:::\n\n::: center\nContents to align center\n:::\n\n::: right\nContents to align right\n:::\n\n\n\n::: justify\nContents to align justify\n:::\n\n`,
  },
  {
    content: `<h2>## Mở rộng/thu gọn</h2>
    <p>+++> Click me!</p>
    <p>Hidden text</p>
    <p>+++ Nested</p>
    <p>Inner hidden text</p>
    <p>+++</p>
    <p>+++></p>
    `,
    markdown: `## Mở rộng/thu gọn\n\n+++> Click me!\nHidden text\n+++ Nested\nInner hidden text\n+++\n+++>`,
  },
  {
    content: `<h2>## Tree list</h2>
    <p>root</p>
    <p>+-- item1</p>
    <p>+-- sub-item1</p>
    <p>+-- item2</p>
    `,
    markdown: `## Tree list\n\nroot\n+-- item1\n+-- sub-item1\n+-- item2`,
  },
  {
    content: `<h2>## UML</h2>
    <p>@startuml</p>
    <p>User --> (registers an account)</p>
    <p>@enduml</p>
    `,
    markdown: `## UML\n\@startuml\nUser --> (registers an account)\n@enduml`,
  },
  {
    content: `<h2>## Kiểu thông báo</h2>
    <p>> [!note]</p>
    <p>> note</p>

    <p>> [!tip]</p>
    <p>> tip</p>

    <p>> [!important]</p>
    <p>> important</p>

    <p>> [!caution]</p>
    <p>> caution</p>

    <p>> [!warning]</p>
    <p>> warning</p>
    `,
    markdown: `## Kiểu thông báo\n\n> [!note]\n> note\n\n> [!tip]\n> tip\n\n> [!important]\n> important\n\n> [!caution]\n> caution\n\n> [!warning]\n> warning\n\n`,
  },
  {
    content: `<h2>## Danh sách không thứ tự </h2>
    <p>* Danh sách được bắt đầu bằng các dấu sau \`+\` \`,\` \`-\` hoặc \`*\` kèm theo 1 dấu cách  /p>
    <p>+ Thay đổi dấu sẽ tạo ra danh sách mới :</p>
    <p>&nbsp;&nbsp;- Tạo danh sách phụ bằng cách ấn phím Tab:</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;* Ac tristique libero volutpat at</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;+ Facilisis in pretium nisl aliquet</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;- Nulla volutpat aliquam velit</p>`,
    markdown: `## Danh sách không theo thứ tự \n\n* Danh sách được bắt đầu bằng các ==dấu== sau \`+\`, \`-\`, or \`*\` kèm theo một dấu cách \n- Thay đổi dấu sẽ tạo ra danh sách mới :\n  - Tạo danh sách phụ bằng cách ấn phím Tab:\n    * Ac tristique libero volutpat at\n    + Facilisis in pretium nisl aliquet\n    - Nulla volutpat aliquam velit`,
  },
  {
    content: `<h2>## Danh sách theo thứ tự</h2>
    <p>1. Lorem ipsum dolor sit amet</p>
    <p>2. Consectetur adipiscing elit</p>
    <p>3. Integer molestie lorem at massa</p>
    <p>1. Bạn có thể dùng số theo thứ tự...</p>
    <p>1. ...hoặc để tất cả là \`1.\`</p>`,
    markdown: `## Danh sách theo thứ tự\n\n1. Lorem ipsum dolor sit amet\n2. Consectetur adipiscing elit\n3. Integer molestie lorem at massa\n\n\n1. Bạn có thể dùng số theo thứ tự ...\n1. ... hoặc để tất cả là \`1.\``,
  },
  {
    content: `<h2>## Code</h2>
    <p>Inline \`code\`</p>
    <strong>Block code "fences"</strong>
    <p>\`\`\`</p>
    <p>Sample text here...</p>
    <p>\`\`\`</p>
    <strong>Syntax highlighting</strong>
    <p>\`\`\` js</p>
    <p>var foo = function (bar) {</p>
    <p>&nbsp;&nbsp;return bar++;</p>
    <p>};</p>
    <p>console.log(foo(5));</p>
    <p>\`\`\`</p>
    <strong>Give it a name </strong>
    <p>\`\`\`js:hello.man</p>
    <p>console.log("hello man");</p>
    <p>\`\`\`</p>
    `,
    markdown: `## Code\n\nInline \`code\`\n**Block code "fences"**\n\`\`\`\nSample text here...\n\`\`\`\n\n**Syntax highlighting**\n\`\`\` js\nvar foo = function (bar) {\n  return bar++;\n};\n\nconsole.log(foo(5));\n\`\`\`\n**Give it a name**\n\`\`\`js:hello.man\n console.log("hello man")\n\`\`\``,
  },
  {
    content: `
      <h2>Math</h2>
<ol>
  <li>
    <p>Inline Math</p>
    <p>\`$sum_(i=1)^n i^3=((n(n+1))/2)^2$\`</p>
  </li>
  <li>
    <p>Block Math</p>
    <p>
      \`\`\`math<br />
      sum_(i=1)^n i^3=((n(n+1))/2)^2 <br />
      \`\`\`
    </p>
  </li>
</ol>
    `,
    markdown: `## Math\n\n1. Inline Math \`$sum\_(i=1)^n i^3=((n(n+1))/2)^2$\`\n\n2. Block Math\n\n\`\`\`math\n\nsum\_(i=1)^n i^3=((n(n+1))/2)^2\n\n\`\`\``,
  },
  {
    content: `<h2>## Blockquotes</h2>
    <div> Blockquotes có thể được lồng với nhau...</div>
    <div>>> ...Bằng cách dùng thêm dấu lớn hơn bên cạnh nhau...</div>
    <div>> > > ...hoặc chèn thêm khoảng cách giữa bọn nó .</div>`,
    markdown: `## Blockquotes\n\nBlockquotes có thể được lồng với nhau...\n>> ...Bằng cách dùng thêm dấu lớn hơn bên cạnh nhau...\n> > > ...hoặc chèn thêm khoảng cách giữa bọn nó .`,
  },
  {
    content: `<h2>## Links</h2>
    <p>[Tên link](http://dev.nodeca.com)</p>
    <p>[Link có tiêu đề](http://nodeca.github.io/pica/demo/ "Hover vào để xem tiêu đề ảnh")</p>
    <p>Tự động convert link https://github.com/nodeca/pica</p>`,
    markdown: `## Links\n\n[Tên link](http://dev.nodeca.com)\n[Link có tiêu đề](http://nodeca.github.io/pica/demo/ "Hover vào để xem tiêu đề ảnh")\n Tự động convert link https://github.com/nodeca/pica`,
  },
  {
    content: `<h2>## Ảnh</h2>
    <strong> Đặt kích thước cho ảnh </strong><br/>
    <span> Bằng cú pháp </span> <span><code> =dàixrộng</code></span>
    <br/>
    <p>
    Nếu bạn chỉ điền mỗi chiều dài thì chiều cao sẽ sóng theo tỉ lệ 
    </p>
    <p>![Minion](https://octodex.github.com/images/minion.png =150x)</p>
    <p>![Stormtroopocat](https://octodex.github.com/images/stormtroopocat.jpg = "The Stormtroopocat" =200x)</p>
    <br />
    <strong>Căn phải</strong>
    <p>&lt;p align="right"&gt;
    <br/>
    &lt;img src="https://octodex.github.com/images/stormtroopocat.jpg"/&gt;
    &lt;/p&gt;</p>`,
    markdown: `## Ảnh\n\n**Đặt kích thước cho ảnh**\n\nBằng cú pháp \`=dàixrộng\`\n\n Nếu bạn chỉ điền mỗi chiều dài thì chiều cao sẽ sóng theo tỉ lệ \n\n ![Minion](https://octodex.github.com/images/minion.png =150x)\n![Stormtroopocat](https://octodex.github.com/images/stormtroopocat.jpg "The Stormtroopocat" =150x)\n**Căn phải**
    <div align='right'>
    <img style="margin:0;width:150px;height:auto;" src="https://octodex.github.com/images/stormtroopocat.jpg">
    <div/>
    `,
  },
  {
    content: `<h2>## Tables</h2>
    <div>| Option | Description |</div>
    <div>| ------ | ----------- |</div>
    <div>| data   | path to data files to supply the data that will be passed into templates. |</div>
    <div>| engine | engine to be used for processing templates. Handlebars is the default. |</div>
    <div>| ext    | extension to be used for dest files. |</div>
    <br />
    <div>
    <strong>Căn chỉnh</strong>
    </div>
    <div>| Option | Description |</div>
    <div>| ------:| :-----------:|</div>
    <div>| Căn phải | Căn giửa |</div>
    <div>| ext    | extension to be used for dest files. |</div>`,
    markdown: `## Tables\n\n| Option | Description |\n| ------ | ----------- |\n| data   | path to data files to supply the data that will be passed into templates. |\n| engine | engine to be used for processing templates. Handlebars is the default. |\n| ext    | extension to be used for dest files. |\n\n Căn chỉnh\n\n| Option | Description |\n| ------:|:-----------:|\n| engine | engine to be used for processing templates. Handlebars is the default. |\n| ext    | extension to be used for dest files. |`,
  },
  {
    content: `<h2>## Tabs</h2>
    <p>
   t1 trong cú pháp là key để phân biệt các tabs khác nhau ,trong cùng 1 tabs thì các tab con của nó có cùng key.
    </p>
    <h3>Cú pháp:</h3>
    <br />

    <p>\`\`\`\[t1:Name tab1\]
    <br />
    Content tab1
    <br />
    \`\`\`</p>
    <p>\`\`\`\[t1:Name tab2\]
    <br />
    Content tab2
    <br />
    \`\`\`
    </p>
    <p>\`\`\`\[t1:Name tab3\]
    <br />
    Content tab3
    <br />
    \`\`\`
    </p>
    `,
    markdown: `## Tabs\n\n\`\`\`[t1:Name tab1]
    Content tab1
\`\`\`

\`\`\`[t1:Name tab 2]
Content tab2
\`\`\`

\`\`\`[t1:Name tab 3]
Content tab3
\`\`\`
`,
  },
  {
    content: `<h2>## Memaid </h2>
   <a href="https://mermaid.js.org/syntax/flowchart.html">Click để xem toàn bộ cú pháp của Mermaid </a>
   <p>
   ~~~mermaid <br/>
   graph TD <br/>
     A[Christmas] -->|Get money| B(Go shopping) <br/>
     B --> C{Let me think} <br/>
     C -->|One| D[Laptop] <br/>
     C -->|Two| E[iPhone] <br/>
     C -->|Three| F[Car] <br/>
 ~~~
 </p>
    `,
    markdown: `## Mermaid\n[Click để xem toàn bộ cú pháp của Mermaid ](https://mermaid.js.org/syntax/flowchart.html)
\n\`\`\`mermaid
graph TD
    A[Christmas] -->|Get money| B(Go shopping)
    B --> C{Let me think}
    C -->|One| D[Laptop]
    C -->|Two| E[iPhone]
    C -->|Three| F[Car]
\`\`\``,
  },
  {
    content: `<h2>## Sử dụng html</h2>
    <p>
    Bạn có thể sử dụng html trong markdown để ==căn chỉnh==, hoặc cho thêm màu sắc cho chữ, tăng kích cỡ chữ
    </p>
    <ol>
    <li>
    <p>Căn chỉnh ảnh</p>
    <code>
    &lt;div align="center"&gt;
    <br/>
    <small>Lưu ý khi sử dụng html với markdown, bạn cần phải cách 1 dòng (như thế này) thì markdown mới hoạt động</small><br/>
    <br/>
    ![Minion](https://octodex.github.com/images/minion.png =150x)
    <br/>
    &lt;/div&gt;
    </code>
    </li>
    <li>
    <p>Tạo bảng bằng html giúp bạn có thêm nhiều tính năng hơn như block code </p>
    <code>
    &lt;table&gt;<br/>
    &lt;tr&gt;<br/>
    &lt;td&gt;
    ==Java==
    &lt;/td&gt;<br/>
    &lt;td&gt;<br/>

 \`\`\`java
 <br/>
 public class Person {
   
}<br/>
\`\`\` <br/>
&lt;/td&gt;<br/>
&lt;/tr&gt;<br/>
&lt;tr&gt;<br/>
&lt;td&gt;Golang&lt;/td&gt;<br/>
&lt;td&gt;<br/>

\`\`\`go<br/>
package main<br/>

import &quot;fmt&quot;<br/>

func main() {
	fmt.Println(&quot;Hello, 世界&quot;)
}<br/>
\`\`\`<br/>
   &lt;/td&gt;<br/>
   &lt;/tr&gt;<br/>
&lt;/table&gt;
  </code>
</li>
    </ol>`,
    markdown: `## Sử dụng html\n\n
<ol>
   <li>
   <p>
   \n\n
   Bạn có thể sử dụng html trong markdown để ==căn chỉnh==, hoặc cho thêm màu sắc cho chữ, tăng kích cỡ chữ
   </p>

   \n\n   
  <div align="center">
  \n\n
  ![Minion](https://octodex.github.com/images/minion.png =150x)\n\n
  </div>
   </li>
   <li>
   \n\n
   Tạo bảng bằng html giúp bạn có thêm nhiều tính năng hơn như \`blockcode\`
   <table>
   <tr>
      <td>
         
==Java==
</td>
 <td>

 \`\`\`java
 public class Person {
 
 }
 \`\`\`         
</td>
   </tr>
   <tr>
      <td>Golang</td>
<td>

\`\`\`go
package main

import "fmt"

func main() {
	fmt.Println("Hello, 世界")
}
\`\`\`
   </td>
   </tr>
</table>
</li>
</ol>
    `,
  },
  {
    content: `<h2>## Typographic</h2>
      <p>(c) (C) (r) (R) (tm) (TM) (p) (P) +-</p>
      <p>test.. test... test..... test?..... test!....</p>
      <p>!!!!!! ???? ,,  -- ---</p>
      <p>"Smartypants, double quotes" and 'single quotes'</p>`,
    markdown: `## Typographic\n\n(c) (C) (r) (R) (tm) (TM) (p) (P) +-\ntest.. test... test..... test?..... test!....\n!!!!!! ???? ,,  -- ---\n"Smartypants, double quotes" and 'single quotes'</p>`,
  },
  {
    content: `<h2>## Subscript/ Superscript</h2>
    <br />
    <p>- 19^th^</p>
    <p>- H~2~O</p>`,
    markdown: `## Subscript/ Superscript\n\n- 19^th\n- H~2~O`,
  },
];

const columns: ColumnsType<{ content: string; markdown: string }> = [
  {
    key: 'content',
    dataIndex: 'content',
    title: 'Component',
    render: (content) => (
      <div dangerouslySetInnerHTML={convertContentHtml(content)}></div>
    ),
  },
  {
    key: 'markdown',
    dataIndex: 'markdown',
    title: 'Markdown',
    render: (markdown) => <ShowMarkdown markdown={markdown} />,
  },
];

const isSeenTooltip: boolean = JSON.parse(
  window.localStorage.getItem('isSeenTooltip') ?? 'false',
);

// eslint-disable-next-line
const MarkdownGuide = (): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(!isSeenTooltip);

  useEffect(() => {
    if (isTooltipVisible) {
      window.localStorage.setItem('isSeenTooltip', JSON.stringify(true));
      const timeoutId = setTimeout(() => {
        setIsTooltipVisible(false);
      }, 5000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  return (
    <Tooltip
      title={
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setIsModalVisible(true);
            setIsTooltipVisible(false);
          }}
        >
          Biểu đồ mermaid mới được cập nhật, click để xem hướng dẫn !
        </div>
      }
      visible={isTooltipVisible}
      zIndex={100}
    >
      <QuestionCircleOutlined
        className="button button-type-link"
        title="Markdown Guide"
        onClick={() => setIsModalVisible(true)}
      />
      {isModalVisible && (
        <Modal
          title="Markdown Guide"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          keyboard={false}
          width={980}
          footer={[]}
        >
          <Wrapper>
            <Table<{ content: string; markdown: string }>
              columns={columns}
              dataSource={guideComponents}
              pagination={false}
              rowKey="content"
              bordered
              size="middle"
            />
          </Wrapper>
        </Modal>
      )}
    </Tooltip>
  );
};

MarkdownGuide.align = 'left';
MarkdownGuide.pluginName = 'markdown-guide';

export default MarkdownGuide;
