import { Input } from 'antd';
import { Image } from '../../models/media.model';
import { ReactElement, useState } from 'react';
import styled from 'styled-components';

import Modal from '../commons/Modal';
import GetAllImages from './all/GetAllImages';
import GetUserImages from './user/GetUserImages';

interface PropsType {
  isModalVisible: boolean;
  closeModal: () => void;
  getUrl: (value: string) => void;
}

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
  }
  input {
    width: 350px;
    padding: 4px 0 4px 8px;
    border: 1px solid black;
    border-radius: 5px;
  }
  @media (max-width: 567px) {
    flex-direction: column;
    align-items: start;
    input {
      width: 250px;
    }
  }
`;

interface MediaTitleProp {
  isSearch: boolean;
  setIsSearch: (value: boolean) => void;
  keyword: string;
  setKeyword: (valu: string) => void;
}

const MediaTitle = ({
  isSearch,
  setIsSearch,
  keyword,
  setKeyword,
}: MediaTitleProp): ReactElement => {
  return (
    <Title>
      <p>{isSearch ? 'Kết quả tìm kiếm' : 'Chọn ảnh của bạn'}</p>
      <Input
        value={keyword}
        placeholder="Tìm kiếm ảnh trong hệ thống"
        onChange={(e) => {
          setKeyword(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            if (keyword === '') setIsSearch(false);
            else setIsSearch(true);
          }
        }}
      />
    </Title>
  );
};

const MediaManagement = ({
  isModalVisible,
  closeModal,
  getUrl,
}: PropsType): ReactElement => {
  const [isSearch, setIsSearch] = useState(false);
  const [keyword, setKeyword] = useState('');

  const [selectedImage, setSelectedImage] = useState<Image>({} as Image);

  return (
    <Modal
      zIndex={105}
      title={
        <MediaTitle
          isSearch={isSearch}
          setIsSearch={setIsSearch}
          keyword={keyword}
          setKeyword={setKeyword}
        />
      }
      visible={isModalVisible}
      onCancel={() => {
        setSelectedImage({} as Image);
        closeModal();
      }}
      keyboard={false}
      width="90%"
      footer={[]}
    >
      {isSearch ? (
        <GetAllImages
          keyword={keyword}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          getUrl={(value) => getUrl(value)}
          closeModal={closeModal}
          setIsSearch={setIsSearch}
          setKeyword={setKeyword}
        />
      ) : (
        <GetUserImages
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          getUrl={(value) => getUrl(value)}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
};
export default MediaManagement;
