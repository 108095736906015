import React, { useCallback, useContext } from 'react';
import { Button, Divider, Space } from 'antd';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  ApartmentOutlined,
  BoldOutlined,
  DashOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  HighlightOutlined,
  ItalicOutlined,
  LayoutOutlined,
  LinkOutlined,
  OrderedListOutlined,
  SplitCellsOutlined,
  StrikethroughOutlined,
  UnorderedListOutlined,
  TagsOutlined,
  UnderlineOutlined,
  MenuOutlined,
  BranchesOutlined,
  ShareAltOutlined,
  AlertOutlined,
} from '@ant-design/icons';
import { ListTree, ListCollapse } from 'lucide-react';
import { BsCode, BsCodeSlash } from 'react-icons/bs';
import styled from 'styled-components';
import ChooseImage from './toolBarBtns/ChooseImage';
import YoutubeLinkBtn from './toolBarBtns/YoutubeLinkBtn';
import TableButton from './toolBarBtns/TableButton';
import HeaderBtn from './toolBarBtns/HeaderBtn';
import { EditorContext as EC } from './EditorContext';
import markers, { alerts } from './markers';
import MarkdownGuide from '../components/markdown/plugins/MarkdownGuide';
import AlertBtn from './toolBarBtns/Alert';

const Toolbar = styled.div`
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: hsl(213, 24%, 93%);
`;

const LeftToolbar = styled.div``;
const RightToolbar = styled.div``;

const activeBtnColor = {
  color: 'rgba(0, 0, 0, 0.85)',
  background: 'rgba(0, 0, 0, 0.028)',
  borderColor: 'transparent',
};
const deactiveBtnColor = {
  color: 'inherit',
  background: 'inherit',
  borderColor: 'none',
};

// eslint-disable-next-line react/display-name
const EditorToolbar = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { state, dispatch } = useContext(EC);

  const { editorView } = state;

  const handleBoldMarker = useCallback(() => {
    if (!editorView) return;

    markers.bold(editorView);
    editorView.focus();
  }, [editorView]);

  const handleItalicMarker = useCallback(() => {
    if (!editorView) return;

    markers.italic(editorView);
    editorView.focus();
  }, [editorView]);

  const handleUnderlineMarker = useCallback(() => {
    if (!editorView) return;

    markers.underline(editorView);
    editorView.focus();
  }, [editorView]);

  const handleStrikethroughMarker = useCallback(() => {
    if (!editorView) return;

    markers.strikethrough(editorView);
    editorView.focus();
  }, [editorView]);

  const handleQuoteMarker = useCallback(() => {
    if (!editorView) {
      return;
    }
    markers.code(editorView);
    editorView.focus();
  }, [editorView]);

  const handleBlockQuote = useCallback(() => {
    if (!editorView) {
      return;
    }
    markers.blockCode(editorView);
    editorView.focus();
  }, [editorView]);
  const handleTabs = useCallback(() => {
    if (!editorView) {
      return;
    }
    markers.tabs(editorView);
    editorView.focus();
  }, [editorView]);
  const handleMermad = useCallback(() => {
    if (!editorView) {
      return;
    }
    markers.mermaid(editorView);
    editorView.focus();
  }, [editorView]);
  const handleLabels = useCallback(() => {
    if (!editorView) {
      return;
    }
    markers.labels(editorView);
    editorView.focus();
  }, [editorView]);
  const handleCollapsible = useCallback(() => {
    if (!editorView) {
      return;
    }
    markers.collapsible(editorView);
    editorView.focus();
  }, [editorView]);
  const handleTreelist = useCallback(() => {
    if (!editorView) {
      return;
    }
    markers.treelist(editorView);
    editorView.focus();
  }, [editorView]);
  const handleUML = useCallback(() => {
    if (!editorView) {
      return;
    }
    markers.uml(editorView);
    editorView.focus();
  }, [editorView]);
  const handleAlert = useCallback(
    (type: alerts) => {
      if (!editorView) {
        return;
      }
      markers.alert(editorView, type);
      editorView.focus();
    },
    [editorView],
  );
  const handleLinkMarker = useCallback(() => {
    if (!editorView) return;

    markers.link(editorView);
    editorView.focus();
  }, [editorView]);

  const handleYoutubeMarker = useCallback(
    (url?: string) => {
      if (!editorView) return;
      markers.pasteYoutube(editorView, url);
      editorView.focus();
    },
    [editorView],
  );

  const handleBulletList = useCallback(() => {
    if (!editorView) return;
    markers.bulletList(editorView);
    editorView.focus();
  }, [editorView]);

  const handleNubmerList = useCallback(() => {
    if (!editorView) return;
    markers.numberList(editorView);
    editorView.focus();
  }, [editorView]);

  const handleAlignment = useCallback(
    (direction) => {
      if (!editorView) return;
      markers.align(editorView, direction);
      editorView.focus();
    },
    [editorView],
  );

  const handleChangePreview = useCallback(() => {
    dispatch({ type: 'previewChanged' });
  }, []);

  const handleChangeFullScreen = useCallback(() => {
    dispatch({ type: 'fullscreenChanged' });
  }, []);

  const handleChangeSplitScreen = useCallback(() => {
    dispatch({ type: 'splitScreenChanged' });
  }, []);

  const handleTable = useCallback(
    (row = 3, column = 3) => {
      if (!editorView) return;
      markers.table(editorView, row, column);
      editorView.focus();
    },
    [editorView],
  );

  const handleHorizontalLine = useCallback(() => {
    if (!editorView) return;
    markers.horizontalLine(editorView);
    editorView.focus();
  }, [editorView]);

  const handleHeader = useCallback(
    (type: number) => {
      if (!editorView) return;
      markers.header(editorView, type);
      editorView.focus();
    },
    [editorView],
  );

  const handleHighLight = useCallback(() => {
    if (!editorView) return;
    markers.highlight(editorView);
    editorView.focus();
  }, [editorView]);

  return (
    <Toolbar style={{ background: 'white' }} ref={ref}>
      <LeftToolbar>
        <Button
          icon={<BoldOutlined />}
          title="In đậm"
          type="text"
          onClick={handleBoldMarker}
        />
        <Button
          icon={<ItalicOutlined />}
          title="In nghiêng"
          type="text"
          onClick={handleItalicMarker}
        />
        <Button
          icon={<UnderlineOutlined />}
          title="Gạch chân"
          type="text"
          onClick={handleUnderlineMarker}
        />
        <HeaderBtn handleHeader={handleHeader} />
        <Button
          title="Labels"
          icon={<TagsOutlined />}
          type="text"
          onClick={handleLabels}
        />
        <Button
          title="Highlight"
          icon={<HighlightOutlined />}
          type="text"
          onClick={handleHighLight}
        />
        <Button
          title="Gạch ngang"
          icon={<StrikethroughOutlined />}
          type="text"
          onClick={handleStrikethroughMarker}
        />

        <Button
          title="Ngăn đoạn"
          icon={<DashOutlined />}
          type="text"
          onClick={handleHorizontalLine}
        />
        <Button
          title="Inline Code"
          icon={<BsCode />}
          type="text"
          onClick={handleQuoteMarker}
        />
        <Button
          title="Block Code"
          icon={<BsCodeSlash />}
          type="text"
          onClick={handleBlockQuote}
        />
        <Button
          title="Link"
          icon={<LinkOutlined />}
          type="text"
          onClick={handleLinkMarker}
        />
        <YoutubeLinkBtn handleYoutubeMarker={handleYoutubeMarker} />
        <Button title="Thư viện ảnh" icon={<ChooseImage />} type="text" />
        <Button
          title="Danh sách không thứ tự"
          icon={<UnorderedListOutlined />}
          type="text"
          onClick={handleBulletList}
        />
        <Button
          title="Danh sách theo thứ tự"
          icon={<OrderedListOutlined />}
          type="text"
          onClick={handleNubmerList}
        />
        <Space
          size={0}
          split={
            <Divider
              type="vertical"
              style={{ margin: '0px', padding: '0px' }}
            />
          }
        >
          <Button
            icon={<AlignLeftOutlined />}
            title="Căn trái"
            type="text"
            onClick={() => handleAlignment('left')}
          />
          <Button
            icon={<AlignCenterOutlined />}
            title="Căn giữa"
            type="text"
            onClick={() => handleAlignment('center')}
          />
          <Button
            title="Căn phải"
            icon={<AlignRightOutlined />}
            type="text"
            onClick={() => handleAlignment('right')}
          />
          <Button
            title="Căn đều"
            icon={<MenuOutlined />}
            type="text"
            onClick={() => handleAlignment('justify')}
          />

          <Button
            title="tree-list"
            icon={<ListTree size={20} />}
            type="text"
            onClick={handleTreelist}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />

          <Button
            title="Collapse"
            icon={<ListCollapse size={20} />}
            type="text"
            onClick={handleCollapsible}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </Space>
        <TableButton handleTable={handleTable} />

        <Button
          title="Tabs"
          icon={<LayoutOutlined />}
          type="text"
          onClick={handleTabs}
        />
        <Button
          title="Mermaid"
          icon={<ApartmentOutlined />}
          type="text"
          onClick={handleMermad}
        />

        <Button
          title="Uml"
          icon={<ShareAltOutlined />}
          type="text"
          onClick={handleUML}
        />
        <AlertBtn handleAlert={handleAlert} />

        <Button icon={<MarkdownGuide />} type="text" />
      </LeftToolbar>

      <RightToolbar>
        <Space size={4} align="center">
          <Button
            title="Xem trước"
            style={state.preview ? activeBtnColor : deactiveBtnColor}
            icon={state.preview ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            type="text"
            onClick={handleChangePreview}
          />
          <Button
            title="Chia màn"
            icon={<SplitCellsOutlined />}
            type="text"
            style={state.splitScreen ? activeBtnColor : deactiveBtnColor}
            onClick={handleChangeSplitScreen}
          />
          <Button
            title="Toàn màn hình"
            style={state.fullscreen ? activeBtnColor : deactiveBtnColor}
            icon={
              state.fullscreen ? (
                <FullscreenExitOutlined />
              ) : (
                <FullscreenOutlined />
              )
            }
            type="text"
            onClick={handleChangeFullScreen}
          />
        </Space>
      </RightToolbar>
    </Toolbar>
  );
});

export default React.memo(EditorToolbar);
