import { Select as SelectAntd } from 'antd';
import { useGetFindLectureQuery } from 'services/onlinecourse.service';
import ServerError from 'components/commons/ServerError';
import { useEffect, useMemo, useRef, useState } from 'react';
import { selectSearch } from 'store/onlinecourse.slice';
import { v4 } from 'uuid';
import LoadingSpin from 'libraries/components/LoadingSpin';
import { SearchOutlined } from '@ant-design/icons';
import { SelectValue } from 'antd/lib/select';
import { generatePath, useHistory } from 'react-router-dom';
import { learning } from 'routers/routeNames';
import { useSelector } from 'react-redux';
import type { OptionGroupData, OptionData } from 'rc-select/lib/interface/index';
import styled from 'styled-components';
import type { RefSelectProps } from 'antd/lib/select/';
import ShowNotFound from 'components/commons/ShowNotFound';

type Props = {
  classId: string;
  value?: string;
};

const Select = styled(SelectAntd)`
  min-width: 42px;
  max-width: 350px;
  width: 100%;
  padding: 0 12px;
  border-radius: 20px;
  border: 1px solid #fff;
  overflow: hidden;
  transition: all linear 0.5s;

  .ant-select-selection-item,
  .ant-select-selection-search {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    color: #fff;
  }

  .my-dropdown {
    padding-left: 50px;
  }

  .ant-select-arrow {
    right: -2px;
    top: 17%;
    color: #fff;
    padding: 21px;
    display: flex;
    font-size: 20px;
    background: #0275d8;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    .ant-select-selection-item,
    .ant-select-selection-search {
      font-size: 16px;
    }
    // .my-dropdown {
    //   padding-left: 50px;
    // }
    // .ant-select-arrow {
    //   right: 24px;
    //   color: #333;
    // }
    // display: block;
    // margin: 78px auto 0;
    // border: 1px solid #000;
  }
`;

const FindLectureInLesson = ({ classId, value = '' }: Props) => {
  const [keyword, setKeyword] = useState('');
  const [search, setSearch] = useState(false);
  const history = useHistory();
  const firstRender = useRef(true);
  const { isLoading, isError, isFetching, error } = useGetFindLectureQuery(
    {
      classes_id: classId,
      keyword: keyword,
    },
    { skip: keyword.length <= 2 },
  );
  const searchResults = useSelector(selectSearch);
  const selectRef = useRef<RefSelectProps>(null);

  useEffect(() => {
    if (!value) return;
    setKeyword(value);
    if (firstRender.current) {
      firstRender.current = false;
    }

    return () => {
      setKeyword('');
    };
  }, [value]);

  const handleSearch = (search: string) => {
    search.length === 0 ? setKeyword(value) : setKeyword(search);
  };

  const optionsGr: OptionGroupData[] = useMemo(
    () =>
      searchResults.map((result, index) => ({
        label: `${index === 0 ? 'Buổi học :' : ''} ${result.name}`,
        key: v4(),
        options: result.lectures.map((lecture) => ({
          title: lecture.title,
          value: lecture.id,
          label: lecture.title,
          lesson_id: result.id,
        })),
      })),
    [searchResults],
  );

  const handleSelect = (arg: SelectValue, options: OptionGroupData | OptionData) => {
    history.push(
      generatePath(learning.learn, {
        classId,
        lessonId: options.lesson_id,
        lectureId: options.value,
      }),
    );
  };

  const handleBlur = () => {
    setKeyword(value);
    setSearch(false);
  };

  const handleClickSearch = () => {
    setSearch(true);
    selectRef.current?.focus();
  };

  // if (isError) {
  //   return <ShowNotFound error={error} />;
  // }
  return (
    <Select
      onClick={handleClickSearch}
      ref={selectRef}
      placeholder="Tìm kiếm bài giảng ..."
      value={keyword}
      filterOption={false}
      showSearch
      bordered={false}
      suffixIcon={<SearchOutlined />}
      options={optionsGr}
      onSelect={handleSelect}
      style={{ width: search ? '100%' : 0 }}
      loading={isLoading}
      notFoundContent={
        isFetching ? (
          <LoadingSpin size="small" text="" fit={true} />
        ) : isError ? (
          <ShowNotFound error={error} />
        ) : null
      }
      onSearch={handleSearch}
      onBlur={handleBlur}
      size="large"
      dropdownClassName="my-dropdown"
      dropdownAlign={{ offset: [-12, 12] }}
    />
  );
};

export default FindLectureInLesson;
