import { FontSizeOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { ReactElement, useMemo } from 'react';

interface iHeaderBtn {
  handleHeader: (type: number) => void;
}
const DropdownHeader = ({ handleHeader }: iHeaderBtn): ReactElement => {
  const onClick = ({ key }: { key: string }) => {
    const type = parseInt(key);
    handleHeader(type);
  };
  const menu = useMemo(() => {
    return (
      <Menu onClick={onClick}>
        <Menu.Item style={{ fontSize: 24 }} key={2}>
          Header 2
        </Menu.Item>
        <Menu.Item style={{ fontSize: 20 }} key={3}>
          Header 3
        </Menu.Item>
        <Menu.Item style={{ fontSize: 16 }} key={4}>
          Header 4
        </Menu.Item>
      </Menu>
    );
  }, [onClick]);
  return (
    <Dropdown overlay={menu}>
      <FontSizeOutlined />
    </Dropdown>
  );
};

const HeaderBtn = ({ handleHeader }: iHeaderBtn) => {
  return (
    <Button title="Header" type="text" icon={<DropdownHeader handleHeader={handleHeader} />} />
  );
};

export default HeaderBtn;
