import MarkdownIt from 'markdown-it';
import subscript from 'markdown-it-sub';
import superscript from 'markdown-it-sup';
import footnote from 'markdown-it-footnote';
import deflist from 'markdown-it-deflist';
import markdownItMermaid from '../utils/markdownItMermaid';
import abbreviation from 'markdown-it-abbr';
import insert from 'markdown-it-ins';
import mark from 'markdown-it-mark';
import copy from 'markdown-it-code-copy';
import highlight from 'markdown-it-highlightjs';
import tabs from 'markdown-it-codetabs';
import hljs from 'highlight.js';
import namedCodeBlock from 'markdown-it-named-code-blocks';

import copyOptions from '../components/markdown/assets/js/copyOptions';
import '@mdi/font/css/materialdesignicons.css';
import MarkdownItLatex from 'markdown-it-latex';

//-- Cuong Hack Begin
import MarkdownItTreelist from 'markdown-it-treelist';
import MarkdownItLabel from 'markdown-it-label';
import MarkdownItCollapsible from 'markdown-it-collapsible';
import { figure } from '@mdit/plugin-figure';
import { imgSize } from '@mdit/plugin-img-size';
import { container } from '@mdit/plugin-container';
import { ins } from '@mdit/plugin-ins';
import { plantuml } from '@mdit/plugin-plantuml';
import { align } from '@mdit/plugin-align';
import { alert } from '@mdit/plugin-alert';
import '@mdit/plugin-alert/style';
import { tab } from '@mdit/plugin-tab';

//-- Cuong Hack End
import 'markdown-it-latex/dist/index.css';
import '../css/markdownItMermaid.css';

const parser = new MarkdownIt({
  html: true,
  xhtmlOut: true,
  breaks: true,
  linkify: true,
  langPrefix: 'language-',
  typographer: true,
});

const tabOptions = {
  name: 'tabs',
};

parser
  .use(markdownItMermaid)
  .use(subscript)
  .use(superscript)
  .use(footnote)
  .use(deflist)
  .use(namedCodeBlock)
  .use(MarkdownItLatex)
  .use(abbreviation)
  .use(insert)
  .use(plantuml)
  .use(mark)
  .use(highlight, { hljs })
  .use(copy, copyOptions)
  .use(tabs)
  .use(MarkdownItTreelist)
  .use(MarkdownItLabel)
  .use(MarkdownItCollapsible)
  .use(figure)
  .use(imgSize)
  .use(container)
  .use(ins)
  .use(plantuml)
  .use(align)
  .use(alert)
  .use(tab, tabOptions);

export default parser;
