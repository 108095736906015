import { forwardRef, ReactElement, Ref } from 'react';
import styled from 'styled-components';
import '@mdi/font/css/materialdesignicons.css';
import './assets/css/code.css';
import './assets/css/expand_collapse.css';
import './assets/css/images.css';
import './assets/css/index.css';
import './assets/css/label.css';
import './assets/css/table.css';
import './assets/css/tabs.css';
import './assets/css/tree.css';

//-- Cuong Hack End
import { Preview } from 'libraries/editor/EditorPreview';
import parser from 'libraries/editor/parser';

interface PropType {
  markdown: string;
  className?: string;
  short_content?: boolean;
  height?: number;
}

// eslint-disable-next-line

const ShortContent = styled(Preview)<{ height?: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  display: -webkit-box;
  & {
    overflow-y: unset;
    border: 0px;
    height: ${({ height }) => height ?? 'auto'};
  }
`;
const Default = styled(Preview)<{ height?: number }>`
  & {
    overflow-y: unset;
    border: 0px;
    height: ${({ height }) => height ?? 'auto'};
  }
`;
const CodeTableStyle = styled.div`
  .code-tabs {
    border: 1px solid rgb(240, 240, 240);
    margin-bottom: 16px;
    border-radius: 3px;
    pre,
    input {
      display: none;
    }
    input:checked + div > pre {
      display: block;
    }
    label {
      color: rgba(191, 191, 191, 1);
      margin-bottom: 0;
    }
    input:checked + label {
      color: rgba(38, 38, 38, 1);
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
    }
    ul {
      font-size: 0;
      padding: 0;
      white-space: nowrap;
      overflow: auto;
      user-select: none;
      border-bottom: 1px solid rgb(240, 240, 240);
    }
    li {
      list-style: none;
      display: inline-block;
      position: relative;
      margin-bottom: 0;
    }
    label {
      cursor: pointer;
      user-select: none;
      display: inline-block;
      padding: 2px 10px;
      margin: 5px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 700;
    }

    pre {
      background-color: #fff;
      border: none;
    }
  }
`;
const ShowMarkdown = forwardRef(
  (
    { markdown, className, short_content, height }: PropType,
    ref: Ref<HTMLDivElement>,
  ): ReactElement => {
    return (
      <CodeTableStyle ref={ref} className="show-markdown editor-preview">
        {short_content ? (
          <ShortContent
            height={height}
            className={className}
            dangerouslySetInnerHTML={{ __html: parser.render(markdown) }}
          ></ShortContent>
        ) : (
          <Default
            height={height}
            className={className}
            dangerouslySetInnerHTML={{ __html: parser.render(markdown) }}
          ></Default>
        )}
      </CodeTableStyle>
    );
  },
);

ShowMarkdown.defaultProps = {
  short_content: false,
};

ShowMarkdown.displayName = 'ShowMarkdown';

export default ShowMarkdown;
