import { Image } from '../../../models/media.model';
import { ReactElement } from 'react';
import Modal from '../../commons/Modal';
import { useUpdateImageInfoMutation } from '../../../services/media.service';
import Form from '../../form/Form';
import FormLabel from '../../form/FormLabel';
import { Input, message } from 'antd';
import Button from '../../../components/commons/Button';
import { useDispatch } from 'react-redux';
import { updateImageInfoInList } from '../../../store/image.slice';

interface PropsType {
  isModalVisible: boolean;
  closeModal: () => void;
  selectedImage: Image;
}

const EditImageInfo = ({ isModalVisible, closeModal, selectedImage }: PropsType): ReactElement => {
  const dispatch = useDispatch();
  const [updateImageInfo] = useUpdateImageInfoMutation();

  // eslint-disable-next-line
  const onFinish = (values: any): void => {
    updateImageInfo({
      id: selectedImage.id,
      title: values.title === undefined ? '' : values.title,
      description: values.description === undefined ? '' : values.description,
    })
      .unwrap()
      .then(() => {
        message.success('Chỉnh sửa thông tin ảnh thành công!');
        dispatch(
          updateImageInfoInList({
            id: selectedImage.id,
            title: values.title === undefined ? '' : values.title,
            description: values.description === undefined ? '' : values.description,
          }),
        );
        closeModal();
      })
      .catch((error) => {
        message.error(error.data);
        console.log('Error: ', error.data);
      });
  };
  return (
    <Modal
      zIndex={102}
      title="Sửa thông tin ảnh"
      visible={isModalVisible}
      onCancel={() => {
        closeModal();
      }}
      keyboard={false}
      width={400}
      footer={[]}
    >
      <Form
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          title: selectedImage.title,
          description: selectedImage.description,
        }}
      >
        <Form.Item style={{ paddingTop: '26px' }}>
          <img alt="example" style={{ width: '100%' }} src={selectedImage.url} />
        </Form.Item>
        <Form.Item
          label={<FormLabel label="Tiêu đề ảnh (description)" require={false} />}
          name="title"
          rules={[{ required: true, message: 'Vui lòng nhập tên ảnh!' }]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          name="description"
          label={<FormLabel label="Mô tả của ảnh" require={false} />}
          // rules={[{ required: true, message: 'Vui lòng nhập mô tả ảnh!' }]}
        >
          <TextArea rows={2} />
        </Form.Item> */}
        <Button.Primary type="primary" htmlType="submit">
          Lưu
        </Button.Primary>
      </Form>
    </Modal>
  );
};
export default EditImageInfo;
