import { ReactElement } from 'react';
import { Row, Col, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { showDrawer } from 'store/general.slice';
import Logo from './Logo';
import User from './User';
import MediaQuery from '../MediaQuery';

const HeaderWrapper = styled.header`
  padding: 11px 25px;
  background: #0275d8;
  position: sticky;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
`;

const Header = (): ReactElement => {
  const dispatch = useDispatch();

  return (
    <HeaderWrapper>
      <Row justify="space-between" align="middle">
        <Col>
          <Logo />
        </Col>
        <Col>
          <User />

          <MediaQuery on={['xs', 'sm', 'md']}>
            <Button style={{ marginLeft: '25px' }} onClick={() => dispatch(showDrawer())}>
              <MenuOutlined />
            </Button>
          </MediaQuery>
        </Col>
      </Row>
    </HeaderWrapper>
  );
};

export default Header;
