import { YoutubeOutlined } from '@ant-design/icons';
import { Dropdown, Input, Button, Form } from 'antd';
import { useState } from 'react';
import CSS from 'csstype';
interface iYoutubeLink {
  handleYoutubeMarker: (input: string) => void;
}
export const styleForm: CSS.Properties = {
  padding: '8px 8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
};
const YoutubeLinkBtn = ({ handleYoutubeMarker }: iYoutubeLink) => {
  const [visible, setVisible] = useState(false);
  const onFinish = (values: any) => {
    const { linkInput: input } = values;
    handleYoutubeMarker(input);
    setVisible(false);
  };

  return (
    <Dropdown
      destroyPopupOnHide={true}
      trigger={['click']}
      visible={visible}
      onVisibleChange={(flag) => setVisible(flag)}
      overlay={
        <Form style={styleForm} name="link" onFinish={onFinish}>
          <Form.Item
            style={{
              padding: 0,
              margin: 0,
            }}
            name="linkInput"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập link Youtube',
              },
              () => ({
                validator(_, value) {
                  let url;
                  if (!value.length) return;

                  try {
                    url = new URL(value);
                  } catch {
                    return Promise.reject(new Error('Không phải url'));
                  }

                  if (url.hostname !== 'www.youtube.com' && url.hostname !== 'youtu.be')
                    return Promise.reject(new Error('Không phải link Youtube'));

                  if (url.searchParams.get('v') || url.hostname === 'youtu.be') {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error('Chưa phải link Youtube hơp lệ'));
                  }
                },
              }),
            ]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item style={{ padding: 0, margin: '4px 0' }}>
            <Button
              style={{
                width: '100%',
              }}
              type="primary"
              htmlType="submit"
            >
              Nhúng
            </Button>
          </Form.Item>
        </Form>
      }
    >
      <Button title="Nhúng clip Youtube" icon={<YoutubeOutlined />} type="text" />
    </Dropdown>
  );
};
export default YoutubeLinkBtn;
