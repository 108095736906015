import 'github-markdown-css/github-markdown-light.css';
import 'highlight.js/styles/atom-one-light.css';
import '@mdi/font/css/materialdesignicons.css';
import '../components/markdown/assets/css/code.css';
import '../components/markdown/assets/css/expand_collapse.css';
import '../components/markdown/assets/css/images.css';
import '../components/markdown/assets/css/index.css';
import '../components/markdown/assets/css/label.css';
import '../components/markdown/assets/css/table.css';
import '../components/markdown/assets/css/tabs.css';
import '../components/markdown/assets/css/tree.css';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { font, fontTxt, hue2, mono1 } from './EditorTheme';
import { useThrottledCallback } from 'use-debounce';
import { EditorContext as EC } from './EditorContext';

export const Preview = styled.div<{
  previewHeight?: number;
  showSplitScreen?: boolean;
}>`
  ${({ showSplitScreen }) =>
    showSplitScreen
      ? css`
          & {
            width: 50%;
            border-bottom-left-radius: 0px;
            border-left: 1px solid #e3e3e3;
            min-height: 100%;
          }
        `
      : css`
          & {
            width: 100%;
            border-bottom-left-radius: 4px;
            border-left: 1px solid #e3e3e3;
            min-height: 100%;
          }
        `}

  ${({ previewHeight }) => css`
    & {
      height: ${previewHeight}px;
    }
  `}
  .named-fence-block {
    position: relative;
  }

  .named-fence-filename {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 8px;
    font-weight: bold;
    text-align: center;
    color: ${hue2};
    background: #dce0e5;
    opacity: 0.8;
  }
  mark {
    background-color: #fff300;
    border-radius: 5px;
    font-weight: 600;
    color: ${mono1};
    padding: 1px 4px;
  }

  & {
    overflow-y: scroll;
    overflow-x: unset;
    border-bottom: 1px solid #e3e3e3;
    padding: 5px 10px;
  }

  & p {
    margin-bottom: 8px;
  }

  & ul {
    margin-bottom: 16px;
    padding-top: 4px;
    p {
      margin-top: 4px;
    }
    li {
      margin-bottom: 8px;
    }
    li > ul li {
      margin-top: 0;
      margin-bottom: 4px;
    }
    li > ul li:last-of-type {
      margin-bottom: 0;
    }
  }

  & h1,
  & h2,
  & h3,
  & h4 {
    border-bottom: 0;
    font-family: 'Helvetica', ${fontTxt};
    padding-bottom: 0;
    margin-top: 24px;
    margin-bottom: 12px;
  }
  code {
    font-family: ${font};
    background-color: #f9e0df;
    color: #8f0e00;
    font-size: 16px;
    padding: 1px 4px;
  }

  & pre {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 8px;
    overflow-y: unset;
    overflow-x: auto;
    background-color: #f6f8fa;
    code {
      font-family: ${font};
      font-size: 16px;
      color: #24292f;
    }
    code.language-properties span {
      color: #24292f;
    }
  }
  & hr {
    height: 2px;
  }
  && > *:first-child > *:first-child {
    // Target dòng đầu tiên
    margin-top: 0;
  }
`;

const EditorPreview: React.FC<{
  className?: string;
  markdown?: string;
}> = ({ className }) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const { state, dispatch } = useContext(EC);

  const { currentActive, scrollPosition, md, html } = state;
  // const [newHtml, setNewHtml] = useState<any>(parser.render(md));
  const onMouseEnter = useCallback(() => {
    dispatch({ type: 'currentActiveChanged', payload: 'preview' });
  }, []);

  const onScroll = useThrottledCallback(() => {
    if (!previewRef || !previewRef.current) return;
    dispatch({
      type: 'scrollPositionChanged',
      payload: previewRef.current.scrollTop / previewRef.current.scrollHeight,
    });
  }, 100);

  useEffect(() => {
    if (!previewRef.current) return;
    if (currentActive == 'preview') return;
    previewRef.current.scrollTop = previewRef.current.scrollHeight * state.scrollPosition;
  }, [previewRef, currentActive, scrollPosition]);

  useEffect(() => {
    dispatch({ type: 'updateHtml', payload: md });
  }, [md]);

  return (
    <Preview
      className={`show-markdown markdown-body editor-preview ${className}`}
      ref={previewRef}
      previewHeight={state.editorHeight}
      showSplitScreen={state.splitScreen}
      onMouseEnter={onMouseEnter}
      onScroll={onScroll}
      dangerouslySetInnerHTML={{ __html: html }}
    ></Preview>
  );
};

export default React.memo(EditorPreview);
