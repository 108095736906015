import { Button, Col, Row } from 'antd';
import { Collapse, Panel } from 'components/commons/Collapse';
import EmptyData from 'components/commons/EmptyData';
import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams, Link, NavLink } from 'react-router-dom';
import { app, learning } from 'routers/routeNames';
import { createModule, selectLessons, selectModules } from 'store/class.slice';
import { setBackLink } from 'store/general.slice';
import useQuery from '../../../libraries/hooks/query';
import ModuleCollapseHeader from './collapse/ModuleCollapseHeader';
import RateModal from './modal/RateModal';
import ParentCollapseHeader from './parentCollapse/ParentCollapseHeader';
import styled from 'styled-components';

const ModuleContainer = styled.div`
  .ant-collapse-header {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #7c7c7c;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    display: block;
    background-color: #e3f2fd;
    border: none;
  }
  .ant-collapse-item-active .ant-collapse-header {
    background-color: #f5f5f5;
  }
`;

const GetModules = (): ReactElement => {
  const lessons = useSelector(selectLessons);

  const modules = useSelector(selectModules);

  const { classroom: cls } = useParams<{ classroom: string }>();

  const [isShowRateModal, setShowRateModal] = useState<boolean>(false);
  const [currentModule, setCurrentModule] = useState('');

  const dispatch = useDispatch();
  const searchParams = useQuery();
  const lessonId = searchParams.get('lesson_id') ?? '';
  const history = useHistory();
  const link = JSON.parse(`${localStorage.getItem(cls)}`);

  useEffect(() => {
    if (!lessons) return;
    const groups = [] as any;

    lessons.forEach((lesson, i) => {
      const groupIndex = groups.findIndex((group: any) => group.name === lesson.group_name);

      if (groupIndex !== -1) {
        groups[groupIndex].lessons.push(lesson);
      } else {
        groups.push({ id: i, name: lesson.group_name, lessons: [lesson] });
      }
    });

    dispatch(createModule(groups));
  }, [lessons]);

  useEffect(() => {
    if (!lessonId.length) return;
    setCurrentModule(lessonId);
    setShowRateModal(true);
  }, []);

  let lectureLink = '';
  if (link === null) {
    lectureLink = generatePath(learning.welcomePage, {
      classId: cls,
    });
  } else {
    lectureLink = generatePath(learning.learn, {
      classId: cls,
      lessonId: link.lessonId,
      lectureId: link.lectureId,
    });
  }
  dispatch(
    setBackLink(
      generatePath(app.classroom, {
        classroom: cls,
      }),
    ),
  );

  const handleOpenRateModal = (e: SyntheticEvent, lessonId: string): void => {
    e.stopPropagation();
    setShowRateModal(true);
    setCurrentModule(lessonId);
  };

  const handleCloseRateModal = () => {
    setCurrentModule('');
    setShowRateModal(false);
  };

  return (
    <ModuleContainer>
      {modules.length > 0 ? (
        <Row gutter={[0, 30]}>
          <Col xs={24}>
            <Collapse accordion>
              {modules.map((module, i) => {
                const { id, name, lessons: lessonList } = module;
                return (
                  <Panel key={id} header={<ParentCollapseHeader moduleId={id} title={name} />}>
                    {lessonList.length > 0 && (
                      <Collapse expandIconPosition="right" accordion>
                        {lessonList.map((lesson, i) => {
                          const {
                            id: moduleId,
                            name,
                            display_order,
                            is_attendance,
                            is_vote,
                            lectures,
                            complete_date,
                          } = lesson;
                          return (
                            <Panel
                              header={
                                <ModuleCollapseHeader
                                  title={` ${name}`}
                                  moduleId={moduleId}
                                  courseId={cls}
                                  isAttendance={is_attendance}
                                  isVote={is_vote}
                                  handleOpenRateModal={handleOpenRateModal}
                                  complete_date={complete_date}
                                />
                              }
                              key={lessonId}
                            >
                              <ol>
                                {lectures.length > 0 &&
                                  lectures.map((item) => (
                                    <li key={item.id}>
                                      <NavLink
                                        to={{
                                          pathname: generatePath(learning.learn, {
                                            classId: cls,
                                            lessonId: moduleId,
                                            lectureId: item.id,
                                          }),
                                          search: `?group_id=${id}`,
                                        }}
                                      >
                                        {item.title}
                                      </NavLink>
                                    </li>
                                  ))}
                              </ol>
                            </Panel>
                          );
                        })}
                      </Collapse>
                    )}
                  </Panel>
                );
              })}
            </Collapse>
          </Col>
          <Col xs={24}>
            <div style={{ textAlign: 'center', margin: '15px' }}>
              <Link to={lectureLink}>
                <Button type="primary">Xem toàn bộ nội dung học</Button>
              </Link>
            </div>
          </Col>
          {isShowRateModal && (
            <RateModal
              closeModal={handleCloseRateModal}
              isShow={isShowRateModal}
              classId={cls}
              lessonId={currentModule}
            />
          )}
        </Row>
      ) : (
        <EmptyData />
      )}
    </ModuleContainer>
  );
};

export default GetModules;
