import { Button, Col, Row } from 'antd';
import { Collapse, Panel } from 'components/commons/Collapse';
import EmptyData from 'components/commons/EmptyData';
import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams, Link } from 'react-router-dom';
import { app, learning } from 'routers/routeNames';
import { selectLessons, createModule } from 'store/class.slice';
import { setBackLink } from 'store/general.slice';
import useQuery from '../../../libraries/hooks/query';
import LessonCollapseHeader from './collapse/LessonCollapseHeader';
import RateModal from './modal/RateModal';

const GetLessons = (): ReactElement => {
  const lessons = useSelector(selectLessons);

  const { classroom: cls } = useParams<{ classroom: string }>();

  const [isShowRateModal, setShowRateModal] = useState<boolean>(false);
  const [currentLesson, setCurrentLesson] = useState('');

  const dispatch = useDispatch();
  const searchParams = useQuery();
  const lessonId = searchParams.get('lesson_id') ?? '';
  const history = useHistory();
  const link = JSON.parse(`${localStorage.getItem(cls)}`);

  useEffect(() => {
    if (!lessonId.length) return;
    setCurrentLesson(lessonId);
    setShowRateModal(true);
  }, []);

  const goToLearnPage = (lessonId: string | undefined, lectureId: string | undefined) => {
    let destinationLessonId = lessonId,
      destinationLectureId = lectureId;

    // Nếu không truyền vào lecture cụ thể thì lấy lecture đang học dở
    if (!destinationLectureId) {
      if (lessons.length > 0) {
        for (let i = 0; i < lessons.length; i++) {
          if (lessons[i].lectures.length > 0) {
            destinationLessonId = lessons[i].id;
            destinationLectureId = lessons[i].lectures[0].id;
            break;
          }
        }
      }
    }

    const url = generatePath(
      learning.learn,
      {
        classId: cls,
        lessonId: destinationLessonId,
        lectureId: destinationLectureId,
      },
    );

    dispatch(
      setBackLink(
        generatePath(app.classroom, {
          classroom: cls,
        }),
      ),
    );
    history.push(url);
  };

  let lectureLink = '';
  if (link === null) {
    lectureLink = generatePath(learning.welcomePage, {
      classId: cls,
    });
  } else {
    lectureLink = generatePath(learning.learn, {
      classId: cls,
      lessonId: link.lessonId,
      lectureId: link.lectureId,
    });
  }
  dispatch(
    setBackLink(
      generatePath(app.classroom, {
        classroom: cls,
      }),
    ),
  );

  const handleOpenRateModal = (e: SyntheticEvent, lessonId: string): void => {
    e.stopPropagation();
    setShowRateModal(true);
    setCurrentLesson(lessonId);
  };

  const handleCloseRateModal = () => {
    setCurrentLesson('');
    setShowRateModal(false);
  };

  return (
    <>
      {lessons.length > 0 ? (
        <Row gutter={[0, 30]}>
          <Col xs={24}>
            <Collapse expandIconPosition="right" accordion>
              {lessons.map((lesson, i) => {
                const {
                  id: lessonId,
                  name,
                  display_order,
                  is_attendance,
                  is_vote,
                  lectures,
                  complete_date,
                } = lesson;
                return (
                  <Panel
                    header={
                      <LessonCollapseHeader
                        title={`Buổi ${i + 1}: ${name}`}
                        lessonId={lessonId}
                        courseId={cls}
                        isAttendance={is_attendance}
                        isVote={is_vote}
                        handleOpenRateModal={handleOpenRateModal}
                        complete_date={complete_date}
                      />
                    }
                    key={lessonId}
                  >
                    <ol>
                      {lectures.length > 0 &&
                        lectures.map((item) => (
                          <li key={item.id}>
                            <a onClick={() => goToLearnPage(lessonId, item.id)}>{item.title}</a>
                          </li>
                        ))}
                    </ol>
                  </Panel>
                );
              })}
            </Collapse>
          </Col>
          <Col xs={24}>
            <div style={{ textAlign: 'center', margin: '15px' }}>
              {/* <a href={lectureLink}>
                <Button type="primary">Xem toàn bộ nội dung học</Button>
              </a> */}
              <Link to={lectureLink}>
                <Button type="primary">Xem toàn bộ nội dung học</Button>
              </Link>
            </div>
          </Col>
          {isShowRateModal && (
            <RateModal
              closeModal={handleCloseRateModal}
              isShow={isShowRateModal}
              classId={cls}
              lessonId={currentLesson}
            />
          )}
        </Row>
      ) : (
        <EmptyData />
      )}
    </>
  );
};

export default GetLessons;
